<template>
  <div>
    <MainLayout>
      <template v-slot:navbar-btn-wrap>
        <DeletePersonnelPopup
          v-if="deleteCkeckboxList.length > 1"
          title="список сотрудников"
          description="Сотрудники потеряют доступ к объекту"
          :name="namePersonnel"
          :dialog="dialog"
          @closeDeletePopup="closeDeletePopup"
          :delete-function="deleteFunction"
        />
        <DeletePersonnelPopup
          v-else
          title="сотрудника"
          description="Сотрудник потеряет доступ к объекту"
          :name="namePersonnel"
          :dialog="dialog"
          @closeDeletePopup="closeDeletePopup"
          :delete-function="deleteFunction"
        />
      </template>

      <TableFilters :queries="[]">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>

            <span v-if="sourceObjectMaxUsers || sourceObject.count">
              {{ sourceObject.count }} из
              {{ sourceObjectMaxUsers ? sourceObjectMaxUsers : "ꝏ" }}
            </span>

            <!--            <span>сотрудников</span>-->
          </span>
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4"
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
        <template v-slot:add-button>
          <router-link
            to="/personnelCreate"
            append
            class="btn btn-add btn_color_green mr-5"
            >Добавить сотрудника
          </router-link>
          <changePersonnelRole
            name="Сотрудника"
            title="Сотрудника"
            :save-function="transferPersonnelRole"
          />
        </template>

        <template v-if="deleteCkeckboxList.length > 1" v-slot:delete-button>
          <v-btn
            :disabled="isDisabled"
            color="#EB5C6D"
            class="btn btn-delete btn_color_red"
            @click="clickDelete"
            style="color:white"
          >
            Удалить сотрудников
          </v-btn>
        </template>
        <template v-else v-slot:delete-button>
          <v-btn
            :disabled="isDisabled"
            color="#EB5C6D"
            class="btn btn-delete btn_color_red"
            @click="clickDelete"
            style="color:white"
          >
            Удалить сотрудника
          </v-btn>
        </template>
      </TableFilters>
      <TableLayout
        disableRouter
        :loading="isDateLoaded"
        :header="header"
        v-if="sourceObject"
        :source-object="sourceObject"
        :table-data="sourceObject.results"
        delete-all
        dropDown
      >
        <template slot-scope="{ itemFather }">
          <v-checkbox
            class="ma-0 pa-0 checkbox-type"
            :value="itemFather.id"
            v-model="deleteCkeckboxList"
            hide-details
          ></v-checkbox>
        </template>
      </TableLayout>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import TableFilters from "@/components/tables/TableFilters";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import changePersonnelRole from "@/components/popup/changePersonnelRole";
import tableColsPersonnel from "@/helpers/tableCols/personnel";
import DeletePersonnelPopup from "@/components/popup/DeletePersonnelPopup";

export default {
  name: "Personnel",
  components: {
    TableFilters,
    TableLayout,
    MainLayout,
    DeletePersonnelPopup,
    changePersonnelRole
  },
  data() {
    return {
      loadingBtn: false,
      dialog: false, // диалоговое окно popup удаления
      telUser: "", // Телефон пользователя
      namePersonnel: "", //Имя персонала
      objUser: {}
    };
  },
  computed: {
    isDisabled() {
      return this.deleteCkeckboxList.length <= 0 ? true : false;
    },
    deleteCkeckboxList: {
      get() {
        return this.$store.getters.getDeleteList;
      },
      set(value) {
        this.$store.commit("UPDATE_DELETE_LIST", value);
        if (this.$store.getters.getCheckedAll.includes("all")) {
          this.$store.commit("SET_CHECKED_ALL", []);
        }
      }
    },
    header() {
      return tableColsPersonnel;
    },
    currentOrganization() {
      return this.$store.getters.getCurrentOrganization;
    },
    urlQuery() {
      return {
        query: {
          ...this.$route.query,
          "organization-personal": this.currentOrganization
        }
      };
    },
    sourceObjectMaxUsers() {
      return this.$store.getters.getDealerInfo.results.free_participant_limit;
    },
    sourceObject() {
      return this.$store.getters.getKindergartenPersonnelList;
    },
    sourceObjectFromUsers() {
      return this.$store.getters.getGroupInfo;
    },
    sourceObjectUsersList() {
      return this.$store.getters.getPageUsersList;
    }
  },
  methods: {
    transferPersonnelRole() {
      this.$store.commit("setRouterQuery", {
        routes: this.$route.params
      });

      this.$store
        .dispatch("changeUserToGroup", {
          data: {
            id: this.$store.getters.getUsers,
            group: localStorage.getItem("newRole")
          }
        })
        .then(() => {
          localStorage.removeItem("oldRole");
          this.$store.dispatch(
            "successNotification",
            actionMessage.change(successMessageDictionary.role)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchKindergartenPersonnelList");
        });
    },
    deleteFunction() {
      let data = {
        ids: this.deleteCkeckboxList,
        kindergartens_pk: this.$store.getters.getCurrentOrganization
      };
      this.$store

        .dispatch("deleteArrPersonal", { data })
        .then(() => {
          this.isDateLoaded = true;
          this.$store.dispatch("fetchKindergartenPersonnelList").finally(() => {
            this.isDateLoaded = false;
          });
        })
        .then(() => {
          this.$store.dispatch("clearCheckedList");
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.personnel)
          );
        });
    },
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadKinderPersonnelList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },

    clickDelete(obj) {
      this.namePersonnel = obj.full_name;
      this.telUser = obj.phone_number;
      this.objUser = obj;
      this.dialog = true;
    }
  },

  created() {
    this.$store.dispatch("clearCheckedList");
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.$store.getters.getCurrentOrganization,
      query: this.urlQuery.query
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchKinderInfo");
    this.$store.dispatch("fetchPageUsersList");
    this.$store.dispatch("fetchKindergartenPersonnelList").finally(() => {
      this.isDateLoaded = false;
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: this.urlQuery.query
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageUsersList");
      this.$store.dispatch("fetchKindergartenPersonnelList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  }
};
</script>

<style scoped></style>
